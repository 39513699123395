<template>
  <div id="canvas">
    <div id="box_wrapper">
      <!-- <main-header /> -->
      <main class="container">
        <div class="navbar d-flex align-itmes-center justify-content-between">
          <div class="icon-container">
            <i v-if="!isHomeRoute" class="pi pi-arrow-left" @click="back()"></i>
          </div>
          <div class="navbar__image mx-auto">
            <img src="/logo.png" width="120" alt="Logo">
          </div>
        </div>
        <slot />
      </main>
      <!-- <main-footer /> -->
    </div>
  </div>
</template>

<script>
// import MainHeader from '@/components/core/blocks/Header/Header.vue'
// import MainFooter from '@/components/core/blocks/Footer/Footer.vue'

export default {
  name: `LayoutDefault`,
  metaInfo: {
    title: '1875 Bay Din'
  },
  components: {
    // MainHeader,
    // MainFooter
  },
  data() {
    return {
        isHomeRoute: false, 
    };
  },
  methods: {
    back(){
      if(this.$route.name == 'orderDetail'){
        history.go(-2)
      }else{
        history.back()
      }
    },
    checkRoute() {
      this.isHomeRoute = this.$route.name === 'home'; 
    },
  },
  mounted() {
    this.checkRoute(); 
  },
  watch: {
    '$route'() {
        this.checkRoute(); 
    },
  },
};

</script>

<style>

.navbar {
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

#box {
  margin: 0 auto;
}

.icon-container {
  width: 0;
  display: flex;
  align-items: center;
}


.icon-container i {
  font-size: 1.8rem;
  padding-left: 30px;
  cursor: pointer;
  color: #6b3591;
}

@media screen and (max-width: 480px){
  .icon-container i {
  font-size: 1rem;
}

.navbar__image img {
  width: 100px;
}
}
/*.LayoutDefault {*/
/*max-width: 42em;*/
/*margin-right: auto;*/
/*margin-left: auto;*/
/*padding-right: 1em;*/
/*padding-left: 1em;*/

/*&__nav {*/
/*padding-top: 1em;*/
/*padding-bottom: 1em;*/
/*border-bottom: 1px solid #c0c0c0;*/
/*}*/

/*&__user {*/
/*float: right;*/
/*}*/

/*&__main {*/
/*padding-top: 1.5em;*/
/*padding-bottom: 2em;*/
/*}*/

/*&__footer {*/
/*padding-top: 1em;*/
/*padding-bottom: 1em;*/
/*border-top: 1px solid #c0c0c0;*/
/*}*/
/*}*/
</style>
<style lang="css" src="@/css/bootstrap.min.css"></style>
<!--<style lang="css" src="@/css/animations.css"></style>-->
<style lang="css" src="@/css/font-awesome.css"></style>
<style lang="css" src="@/css/icomoon.css"></style>
<style lang="css" src="@/css/main.css"></style>

